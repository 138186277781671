import { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import AttendanceSearch from "../AttendanceSearch";
import AttendanceCurrentSearch from "./AttendanceCurrentSearch";
import LayoutWrapper from "../LayoutWrapper";
import moment from "moment";
import { CheckIcon } from "src/imagepath/imagepath";

export class AttendanceDetailsCurrent extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceDetailsCurrent");
    this.state = {
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      touchedRows: [],
      initiallySelectedRowKeys: [],
      rowcount: 0,
      search: {},
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      loader: false,
      schools: [],
      student_data: [],
    };
    this.columns = [
      {
        dataIndex: "user_id",
        title: "User ID",
      },
      {
        dataIndex: "curriculum_name",
        title: "Class Name",
      },
      {
        dataIndex: "section_name",
        title: "Section",
      },
      {
        dataIndex: "student_name",
        title: "Student Name",
      },
    ];
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList(data) {
    let payload = {
      ...data,
      ...this.state.search,
      section_id: this.state.search?.event_data?.section_id,
    };
    if (
      this.props.urlparams.date != undefined &&
      this.props.urlparams.event != undefined &&
      payload?.section_id
    ) {
      payload = {
        ...payload,
        event_id: this.props.urlparams.event,
        date: this.props.urlparams.date,
      };
    }
    this.setState({
      search: {
        ...this.state.search,
      // section_id: this.state.search?.event_data?.section_id,
      },
    });
    if (payload.section_id !== undefined) {
      this.setState({
        loader: true,
      });
      this.props.api
        .apiGetExistingAttendanceBySectionId(
          { section_id: payload.section_id, event_id: payload?.event_id },
          Object.only(payload, ["profile_id", "date"])
        )
        .then(({ data, total }) => {
          const presentRows = data.filter(
            (row) => row.attendance_status === "PRESENT"
          );
          const selectedRowKeys = presentRows.map((row) => row.profile_id);

          this.setState({
            data: data,
            student_data: data,
            rowcount: total,
            loader: false,
            selectedRowKeys,
            selectedRows: presentRows,
            initiallySelectedRowKeys: selectedRowKeys,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
          toast.error("Error fetching attendance");
        });
    }
  }

  downloadFile() {}

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      }
      // () => this.fetchList()
    );
  }

  onSearch(data) {
    console.log("onSearch data: ", data);

    this.setState(
      {
        search: {
          ...this.state.search,
          ...Object.except(data, ["class_id"]),
        },
      },
      () => {
        this.fetchList();
      }
    );
  }

  onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    console.log({ selectedRowKeys, selectedRows });

    const { data } = this.state;
    // Find rows whose status changed (either checked or unchecked)
    const touchedRows = data.filter((row) => {
      const wasPreviouslySelected = this.state.selectedRowKeys.includes(
        row.profile_id
      );
      const isNowSelected = selectedRowKeys.includes(row.profile_id);
      return wasPreviouslySelected !== isNowSelected; // Status changed
    });
    this.setState({
      selectedRowKeys: selectedRows.map((row) => row.profile_id),
      selectedRows,
      touchedRows: [...this.state.touchedRows, ...touchedRows], // Keep track of touched rows
    });
  };

  updateAttendance = () => {
    const { touchedRows, data } = this.state;
    this.setState({
      loader: true,
    });
    // Format the touched rows to send to the API
    const updatedRows = data.map((row) => ({
      profile_id: row.profile_id,
      attendance_status: this.state.selectedRowKeys.includes(row.profile_id)
        ? "PRESENT"
        : "ABSENT",
    }));

    // Check if there are any changes to send
    if (updatedRows.length === 0) {
      console.log("No changes to update.");
      return;
    }
    let payload = {
      section_id: this.state.search?.section_id,
      event_id: this.state.search?.event_id,
      attendance: updatedRows,
    };
    let { date } = this.props.urlparams;
    if (date !== undefined) {
      payload.attendance_date = date;
    }
    this.props.api
      .apiUpdateAttendance(payload)
      .then((response) => {
        this.setState({
          loader: false,
        });
        if(this.props.urlparams.date != undefined){
          warn(
            <div>
             Attendance marked successfully <br/>
             for the {this.state.search?.event_data?.session_name} on {moment(this.props.urlparams.date)?.format("DD-MM-YYYY")}
            </div>,
            { okText: "Close", extra: true }
          ).promise.then(() => {
            this.props.navigate("/schedulemanagement/editevent")
          })

        }else{
          toast.success("Attendance updated successfully");
          this.fetchList();
        }
      })
      .catch((error) => {
        toast.error("Error updating attendance");
        this.setState({
          loader: false,
        });
      });
  };

  render() {
    let { data = [], rowcount, loader, search, selectedRowKeys } = this.state;
    let { columns } = this;
    let { date } = this.props.urlparams;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    return (
      <LayoutWrapper
        title={`Attendance- ${date!==undefined?moment(date)?.format("DD/MM/YYYY") : moment(date)?.format("DD/MM/YYYY")}`}
        back=""
      >
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <ToastContainer position="top-right" />
        <AttendanceCurrentSearch
          student_data={this.state.student_data}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row schoolmanagement">
          <div className="col-12">
            <div></div>
            {!!data.length && (
              <AppTable
                data={data}
                columns={columns}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                onChange={(...arg) => this.onPageChange(...arg)}
                total={rowcount}
                reorder={true}
                isRowDeletable={() => false}
                isRowEditable={() => false}
                viewable={false}
                // targetType="tap"
                isresponsive={true}
                checkbox={true}
                rowSelection={rowSelection}
                isCustomRowKey={true}
                customRowKey={"profile_id"}
              ></AppTable>
            )}
            {data.length == 0 && (
              <div className="empty_attendance_layout bg-white"></div>
            )}
          </div>
        </div>
        {data.length > 0 && (
          <div className="d-flex justify-content-end bg-white">
            <div className="col-2 m-2">
              <button
                type="button"
                className="btn btn-success btn-block w-100"
                onClick={() => this.updateAttendance()}
              >
                Update
              </button>
            </div>
          </div>
        )}
      </LayoutWrapper>
    );
  }
}

export default connect(AttendanceDetailsCurrent);
