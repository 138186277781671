export default {
  apiGetAttendanceBySectionIdForAdmin(payload,urldata = {}, event_data) {
    return this.request(this.api().getAttendanceBySectionIdForAdmin,payload)
      .urltransform(urldata)
      .exec()
      .log()
      .mapresults((response) => {
        let data = response.data[0].records.map((obj) => {
          let today = new Date();
          let month_year = urldata.month_year?.split("-");
          let attendance = new Date(month_year[0], month_year[1] - 1, 1)
            .dateList()
            .reduce((o, n) => {
              // let status = statuses[n.getDayName()] || "nostatus";
              let status = "absent";
              if (n.getDayName() === "SUN" && "sunday") {
                // console.log((n.getDayName() === "SUN" && "sunday"));
                // console.log(n.getDayName(), status);
              }
              // if (n.getDayName() === "SUN") {
              //     console.log({ n });
              //     status = "sunday"
              // }
              // status = (holidays.includes(n.toDate()) && "holiday") || status;
              status =
                (obj.attendances
                  .filter((o) => o.status == "PRESENT" || o.status == "WIP")
                  .map((o) => (o.attendance_date || "").date().toDate())
                  .includes(n.toDate()) &&
                  "present") ||
                status;
              status =
                (obj.attendances
                  ?.filter((o) => o.status == "ABSENT")
                  .map((o) => (o.attendance_date || "").date().toDate())
                  .includes(n.toDate()) &&
                  "absent") ||
                status;
              status = (n.getDayName() === "SUN" && "sunday") || status;
              // status =
              //   ((n > today ||
              //     (n < today &&
              //       n.getDate() !==
              //         new Date(event_data?.event_date).getDate())) &&
              //     "nostatus") ||
              //   status;
              let filteredAttendance = obj.attendances.find((item) =>
                (item.attendance_date || "")
                  .date()
                  .toDate()
                  .includes(n.toDate())
              );
              return {
                ...o,
                [n.getDate()]: {
                  status: status,
                  attendance_id: filteredAttendance?.attendance_id,
                },
              };
            }, {});
          return {
            name: obj.name,
            profile_id: obj.profile_id,
            ...attendance,
          };
        });
        // console.log(data);
        return {
          data: data,
          rawData: response?.data[0]?.records,
          total: response.data[0].pagination[0]?.totalRecords || 0,
        };
      })
      .get();
  },
  apiGetExistingAttendanceBySectionId(urldata, payload) {
    // if(!urldata.profile_data){
    //   urldata.profile_data=undefined;
    // }
    return (
      this.request(this.api().getExistingAttendanceBySectionId, payload)
        .urltransform(urldata)
        .exec()
        .log()
        // .mapresults((response) => {
        //   return {
        //     data: response.data[0].records,
        //     total: response.data[0].pagination[0]?.totalRecords || 0,
        //   };
        // })
        .get()
    );
  },
  apiUpdateAttendance(payload) {
    return this.request(this.api().updateAttendance, payload)
      .exec()
      .log()
      .get();
  },
  apiUpdateAttendanceHistory(urldata = mixins.params(), payload) {
    return this.request(this.api().updateAttendanceHistory, payload)
      .urltransform(urldata)
      .exec()
      .log()
      .get();
  },
};
