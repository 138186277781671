import moment from "moment";
import { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Helmet } from "react-helmet";
import _enum from "src/mixins/enum";

const months = Object.entries([
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
]).map(([key, value]) => ({ key, value }));

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: [],
      invoiceData: [],
      rowcount: 0,
      search: {
        limit: 5,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      series: [],
      options: {},
      series2: [],
      options2: {},
      searchFields: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        school_id: null,
        class_id: null,
        module_id: null,
      },
      fields: [
        {
          name: "month",
          type: "month",
          label: "Month & Year",
          className: "status-dropdown",
          placeholder: "Month",
          onChange: (event) => {
            let d = new Date(event.split(",")[0]);
            if (d) {
              this.setState(
                {
                  searchFields: {
                    ...this.state.searchFields,
                    month: d.getMonth(),
                    year: d.getFullYear(),
                  },
                },
                () => {
                  // this.fetchSessionProgressBarChart();
                  this.fetchMonthlyEventProgress();
                }
              );
            }
          },
          defaultValue: `${
            months.find(({ key }, i) => parseInt(key) === new Date().getMonth())
              .value
          }-${new Date().getFullYear()}`,
        },
        {
          type: "select",
          options: [],
          onChange: (e) => {
            this.handleSchoolClassModuleChange(e, "school_id");
          },
          name: "school_id",
          label: "School",
          placeholder: "All",
        },
        {
          type: "select",
          options: [],
          onChange: (e) => {
            this.handleSchoolClassModuleChange(e, "class_id");
          },
          name: "class_id",
          label: "Class",
          placeholder: "All",
        },
        // {
        //   type: "select",
        //   options: [],
        //   onChange: (e) => {
        //     this.handleSchoolClassModuleChange(e, "module_id");
        //   },
        //   name: "module_id",
        //   placeholder: "All",
        // },
        {
          type: "select",
          options: [],
          onChange: (e) => {
            this.handleSchoolClassModuleChange(e, "section");
          },
          name: "section",
          label: "Section",
          placeholder: "All",
        },
      ],
    };
  }

  componentDidMount() {
    // if (localStorage.getItem("currentAdminRole") !== "SCHOOL_COORDINATOR") {
    if (localStorage.getItem("currentAdminRole") === "SUPER_ADMIN") {
      this.fetchDashboardStats();
    }
    if (localStorage.getItem("currentAdminRole") === "SCHOOL_ADMIN") {
      // let schoolNames = this.state.fields.find((o) => o.name == "school_id");
      // schoolNames.type = "text";
      // schoolNames.readOnly = true;
      // schoolNames.defaultValue = JSON.parse(
      //   localStorage.getItem("profile-data")
      // )?.school_name;
      // this.setState(
      //   {
      //     ...this.state.fields,
      //     searchFields: {
      //       ...this.state.searchFields,
      //       school_id: localStorage.getItem("school_id"),
      //     },
      //   },
      //   () => {
      //     this.getClassBySchool();
      //     this.fetchSessionProgressBarChart();
      //   }
      // );
      // this.fetchDashboardBarChart();
    } else if (localStorage.getItem("currentAdminRole") === "SUPER_ADMIN") {
      this.getSchoolNames();
      // this.fetchSessionProgressBarChart();
      this.fetchMonthlyEventProgress();
    }
  }

  transformData(classesData) {
    console.log({ classesData });
    const transformedData = [];
    const modules = ["Conceptual", "Personality", "Engineering & Technology"];
    const moduleMap = new Map(modules.map((key) => [key, []]));

    classesData.forEach((classItem, i) => {
      modules.forEach((moduleItem) => {
        let module = classItem.modules.find(
          (mod) => mod.module_name === moduleItem
        );
        moduleMap.get(moduleItem).push(module?.progress || 0);
      });
    });

    moduleMap.forEach((progressArray, moduleName) => {
      transformedData.push({
        name: moduleName,
        data: progressArray,
      });
    });
    return transformedData;
  }

  fetchDashboardStats() {
    let schoolId = localStorage.getItem("school_id");
    console.log({ schoolId });
    this.props.api
      .apiDashboardData({ school_id: schoolId })
      .then(({ data }) => {
        let op = [];
        if (localStorage.getItem("currentAdminRole") === "SCHOOL_ADMIN") {
          op = [
            {
              formName: "Total Class",
              count: data?.classCount,
              icon: imagepaths.dashboardClassIcon,
            },

            {
              formName: "Total Students",
              count: data?.studentCount,
              icon: imagepaths.dashboardStudent,
            },
            {
              formName: "Total Modules",
              count: data?.moduleCount,
              icon: imagepaths.dashboardModules,
            },
            {
              formName: "Total Session",
              count: data?.sessionCount,
              icon: imagepaths.dashboardSession,
            },
          ];
          let invoiceData = [
            {
              formName: "Total Invoice",
              count: data?.totalInvoice,
              icon: imagepaths.InvoiceDashboardIcon,
            },

            {
              formName: "Paid Invoice",
              count: data?.paidInvoice,
              icon: imagepaths.InvoicePaid,
            },
            {
              formName: "Last paid on",
              count: moment
                .utc(data?.lastPaidInvoice?.updatedAt)
                ?.format("DD/MM/YYYY"),
              icon: imagepaths.InvoiceCalender,
            },
            {
              formName: "Last paid Amount",
              count: data?.lastPaidInvoice?.invoice_amount,
              icon: imagepaths.InvoicePaidAmount,
            },
          ];
          this.setState({
            invoiceData: invoiceData,
            dashboardData: op,
          });
        } else {
          op = [
            {
              formName: "Active Schools",
              count: data?.schoolsCount,
              icon: imagepaths.dashboardSchoolsIcon,
            },
            {
              formName: "Total Classes",
              count: data?.classCount,
              icon: imagepaths.dashboardClassIcon,
            },
            {
              formName: "Total Sections",
              count: data?.sectionCount,
              icon: imagepaths.dashboardSectionsIcon,
            },
            {
              formName: "Enrolled Students",
              count: data?.studentCount,
              icon: imagepaths.dashboardStudentsIcon,
            },
          ];

          this.setState({
            dashboardData: op,
          });
        }
      });
  }

  fetchDashboardBarChart() {
    let schoolId = localStorage.getItem("school_id");
    this.props.api
      .apiGetDashboardBarChartData({ school_id: schoolId })
      .then(({ data }) => {
        if (data?.length > 0) {
          this.setState({
            series: this.transformData(data),
            options: {
              chart: {
                type: "bar",
                height: 650,
              },
              stroke: {
                width: 1,
                colors: ["#fff"],
              },
              dataLabels: {
                formatter: (val) => {
                  return val?.toFixed(2) + "%";
                },
              },
              plotOptions: {
                bar: {
                  columnWidth: "50px",
                },
              },
              xaxis: {
                categories: data.map(
                  (item) => item?.curriculum_details?.curriculum_grade
                ),
                labels: {
                  formatter: (val) => {
                    return "Class " + val;
                  },
                },
                title: {
                  text: "Classes",
                  style: {
                    fontSize: "12px",
                    fontWeight: "600px",
                    fontFamily: "Lexend",
                    color: "#8F8787",
                  },
                },
                axisBorder: {
                  show: true,
                  color: "#000000",
                },
              },
              yaxis: {
                title: {
                  text: "Completion %",
                  style: {
                    fontSize: "12px",
                    fontWeight: "600px",
                    fontFamily: "Lexend",
                    color: "#8F8787",
                    marginBottom: "10px",
                  },
                },
                axisBorder: {
                  show: true,
                  color: "#000000",
                },
                labels: {
                  formatter: (val) => {
                    return val?.toFixed(2) + "%";
                  },
                },
              },
              fill: {
                opacity: 1,
              },
              colors: ["#FBA106", "#111C3A", "#FFCB71"],
              legend: {
                position: "bottom",
                horizontalAlign: "center",
                itemMargin: {
                  horizontal: 10,
                  vertical: 5,
                },
              },
            },
          });
        }
      });
  }

  fetchSessionProgressBarChart() {
    this.props.api
      .apiGetSessionProgressBarChart({
        month: this.state.searchFields.month,
        year: this.state.searchFields.year,
        school_id: this.state.searchFields.school_id,
        class_id: this.state.searchFields.class_id,
        module_id: this.state.searchFields.module_id,
      })
      .then(({ data }) => {
        this.setState({
          series2: [
            {
              name: "Sessions",
              data: data?.map((item) => item.count) || [],
            },
          ],
          options2: {
            chart: {
              type: "bar",
              height: 650,
            },
            stroke: {
              width: 1,
              colors: ["#fff"],
            },
            dataLabels: {
              formatter: (val) => {
                return val;
              },
            },
            plotOptions: {
              bar: {
                columnWidth: "50px",
              },
            },
            xaxis: {
              categories: data.map((item) => item?.monthWeekNumber) || [],
              labels: {
                formatter: (val) => {
                  return "Week " + val;
                },
              },
              title: {
                text: "Weeks",
                style: {
                  fontSize: "12px",
                  fontWeight: "600px",
                  fontFamily: "Lexend",
                  color: "#8F8787",
                },
              },
              axisBorder: {
                show: true,
                color: "#000000",
              },
            },
            yaxis: {
              title: {
                text: "Number of Completed Sessions",
                style: {
                  fontSize: "12px",
                  fontWeight: "600px",
                  fontFamily: "Lexend",
                  color: "#8F8787",
                  marginBottom: "10px",
                },
              },
              axisBorder: {
                show: true,
                color: "#000000",
              },
              labels: {
                formatter: (val) => {
                  return val;
                },
              },
            },
            fill: {
              opacity: 1,
            },
            colors: ["#FFCB71"],
            legend: {
              position: "bottom",
              horizontalAlign: "center",
              itemMargin: {
                horizontal: 10,
                vertical: 5,
              },
            },
          },
        });
      });
  }

  fetchMonthlyEventProgress() {
    this.props.api
      .apiGetMonthlyEventProgress({
        month: this.state.searchFields.month + 1,
        year: this.state.searchFields.year,
        school: this.state.searchFields.school_id,
        class: this.state.searchFields.class_id,
        section: this.state.searchFields.section,
      })
      .then(({ data }) => {
        const weeks = data.map((entry,i) => `Week ${entry?.index}`);

        const series = _enum.EVENT_STATUS.map((status) => ({
          name: status?.label,
          data: data.map((entry) => {
            const stat = entry.stats.find((s) => s.status === status.key);
            return stat ? stat.count : 0;
          }),
        }));
        const options = {
          chart: {
            type: "bar",
            height: 450,
            stacked: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "35px",
            },
          },
          colors: ["#E2E3E3", "#F4D3CC","#ECFDF5"],
          xaxis: {
            categories: weeks,
          },
          yaxis: {
            title: {
              text: "Number of Events",
            },
          },
          legend: {
            position: "bottom",
          },
          fill: {
            opacity: 1,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["#B5B5B5", "#F53D15", "#64C391"],
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#B5B5B5", "#F53D15", "#64C391"],
              // fontSize: "12px",
              fontWeight: "bold",
            },
          },
        };
        this.setState({
          series3: series,
          options3: options,
        });
      })
      .catch((error) => {});
  }

  getSchoolNames = () => {
    this.props.api
      .apiGetAllSchoolListSuperAdmin()
      .then(({ data, total }) => {
        let schoolNames = this.state.fields.find((o) => o.name == "school_id");
        schoolNames.options = [
          { key: "", label: "All" },
          ...data?.options("school_id", "school_name"),
        ];
        this.setState({
          ...this.state.fields,
        });
      })
      .catch(() => {});
  };

  getClassBySchool = () => {
    this.props.api
      .apiGetClassById({ school_id: this.state.searchFields.school_id })
      .then((response) => {
        let data = response.data[0].records;
        data = data.sort((a, b) => a.curriculum_level - b.curriculum_level);
        let class_id = this.state.fields.find((o) => o.name == "class_id");
        class_id.options = [
          { key: "", label: "All" },
          ...data?.options("class_id", "curriculum_name"),
        ];
        if (localStorage.getItem("currentAdminRole") === "SCHOOL_ADMIN") {
          let schoolNames = this.state.fields.find(
            (o) => o.name == "school_id"
          );
          schoolNames.type = "text";
          schoolNames.readOnly = true;
          schoolNames.defaultValue = JSON.parse(
            localStorage.getItem("profile-data")
          )?.school_name;
        }
        this.setState({
          ...this.state.fields,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loader: false,
        });
      });
  };

  getModulesByClassSchool = () => {
    this.props.api
      .apiGetAllModules({
        school_id: this.state.searchFields.school_id,
        class_id: this.state.searchFields.class_id,
      })
      .then((response) => {
        let data = response.data[0].records;
        let module_id = this.state.fields.find((o) => o.name == "module_id");
        module_id.options = [
          { key: "", label: "All" },
          ...data?.options("module_id", "module_name"),
        ];
        this.setState({
          ...this.state.fields,
        });
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  };
  getSectionByClassSchool = (sectionData) => {
    let section = this.state.fields.find((o) => o.name == "section");
    section.options = [
      { key: "", label: "All" },
      ...sectionData?.options("section_id", "name"),
    ];
    this.setState({
      ...this.state.fields,
    });
  };

  handleSchoolClassModuleChange(e, id) {
    switch (id) {
      case "school_id":
        this.setState(
          {
            ...this.state.fields,
            searchFields: {
              ...this.state.searchFields,
              school_id: e[0]?.school_id,
            },
          },
          () => {
            // this.fetchSessionProgressBarChart();
            this.fetchMonthlyEventProgress();
            e[0]?.school_id && this.getClassBySchool();
          }
        );
        break;
      case "class_id":
        this.setState(
          {
            searchFields: {
              ...this.state.searchFields,
              class_id: e[0]?.class_id,
            },
          },
          () => {
            // this.fetchSessionProgressBarChart();
            this.fetchMonthlyEventProgress();
            // e[0]?.class_id && this.getModulesByClassSchool();
            e[0]?.class_id && this.getSectionByClassSchool(e[0].section);
          }
        );
        break;
      case "module_id":
        this.setState(
          {
            searchFields: {
              ...this.state.searchFields,
              module_id: e[0]?.module_id,
            },
          },
          () => {
            this.fetchMonthlyEventProgress();
            // this.fetchSessionProgressBarChart();
          }
        );
        break;

      case "section":
        this.setState(
          {
            searchFields: {
              ...this.state.searchFields,
              section: e[0]?.section_id,
            },
          },
          () => {
            this.fetchMonthlyEventProgress();
            // this.fetchSessionProgressBarChart();
          }
        );
        break;

      default:
        break;
    }
  }

  render() {
    let { dashboardData, invoiceData, fields } = this.state;
    return (
      <>
        <Helmet>
          <title>Dashboard - CognoSpace</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            {localStorage.getItem("currentAdminRole") ===
            "SCHOOL_COORDINATOR" ? (
              <>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-lg-1 col-sm-1">
                        <img
                          src={window.store.getState().root.schoolLogo}
                          width="76px"
                          height="66px"
                          alt="Image"
                        />
                      </div>
                      <div className="col dashboard-title">
                        <h3 className="page-title">{`Welcome to ${localStorage.getItem(
                          "school_name"
                        )} Dashboard`}</h3>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item active">Home</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard_layout mt-5"></div>
              </>
            ) : localStorage.getItem("currentAdminRole") === "SCHOOL_ADMIN" ? (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-lg-1 col-sm-1">
                      <img
                        src={window.store.getState().root.schoolLogo}
                        width="76px"
                        height="66px"
                        alt="Image"
                      />
                    </div>
                    <div className="col dashboard-title">
                      <h3 className="page-title">{`${
                        window.store.getState().root.schoolName
                      }`}</h3>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item active">Welcome</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="dashboard_layout mt-5"></div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Dashboard</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          {/* /Page Header */}
          <div className="row">
            {dashboardData?.map((o, i) => (
              <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                <div
                  className="card dash-widget cursor-pointer"
                  // onClick={() => this.cardAction(o, i)}
                >
                  <div className="card-body flex justify-content-start align-items-center">
                    <span className="dash-widget-icon">
                      <img src={o.icon} alt="img" width="40px" height="40px" />
                      <div class="vr text-white ms-3"></div>
                    </span>
                    <div className="dash-widget-info">
                      <h3>{o.count}</h3>
                      <span className="formName">{o.formName}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*{localStorage.getItem("currentAdminRole") === "SCHOOL_ADMIN" && (
            <>
              <div className="row my-2">
                <span>Invoice</span>
              </div>
              <div className="row">
                {invoiceData?.map((o, i) => (
                  <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3" key={i}>
                    <div
                      className="card dash-widget cursor-pointer"
                      // onClick={() => this.cardAction(o, i)}
                    >
                      <div className="card-body dash-widget-style-2 flex justify-content-start align-items-center">
                        <span className="dash-widget-icon">
                          <div><img className="mb-2" src={o.icon} alt="img" width="30px" height="30px" /></div>
                          <div class="vr text-white ms-3"></div>
                        </span>
                        <div className="dash-widget-info">
                          <h3>{o.count}</h3>
                          <span className="formName">{o.formName}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="sub-header">Class Progress By Module</div>
              {this.state.series && (
                <div>
                  <div id="chart">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="bar"
                      height={350}
                    />
                  </div>
                  <div id="html-dist"></div>
                </div>
              )} 
            </>
          )}*/}
          {/* {["SUPER_ADMIN", "SCHOOL_ADMIN"].includes( */}
          {/* {["SUPER_ADMIN"].includes(
            localStorage.getItem("currentAdminRole")
          ) && (
            <>
              <div className="sub-header">Weekly Session Progress</div>
              {this.state.series2 && (
                <div>
                  <div id="chart">
                    <div className="row justify-content-end">
                      {fields.map((field, i) => (
                        <div className="col-sm-6 col-md-3" key={i}>
                          <AppInputFocus {...field}></AppInputFocus>
                        </div>
                      ))}
                    </div>
                    {this.state.series2[0]?.data?.length > 0 ? (
                      <ReactApexChart
                        options={this.state.options2}
                        series={this.state.series2}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <div className="no_data_dashboard_layout"></div>
                    )}
                  </div>
                  <div id="html-dist"></div>
                </div>
              )}
            </>
          )}  */}
          {["SUPER_ADMIN"].includes(
            localStorage.getItem("currentAdminRole")
          ) && (
            <>
              <div className="sub-header">Monthly Event Progress</div>
              {this.state.series3 && (
                <div>
                  <div id="chart">
                    <div className="row justify-content-end ml-3">
                      {fields.map((field, i) => (
                        <div className="col-sm-6 col-md-3" key={i}>
                          <AppInput {...field}></AppInput>
                        </div>
                      ))}
                    </div>
                    {this.state.series3[0]?.data?.length > 0 ? (
                      <ReactApexChart
                        options={this.state.options3}
                        series={this.state.series3}
                        type="bar"
                        height={350}
                      />
                    ) : (
                      <div className="no_data_dashboard_layout"></div>
                    )}
                  </div>
                  <div id="html-dist"></div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default connect(Dashboard);
