import moment from "moment";
import { Component } from "react";
import AttendanceSearch from "../AttendanceSearch";
import { toast } from "react-toastify";
import DayAttendanceCell from "./DayAttendanceCell";

export class AttendanceDetailsHistory extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AttendanceDetailsHistory");
    this.state = {
      loader: false,
      monthDate: new Date(),
      attendanceData: [],
      employeeDataObj: [],
      newColumns: [],
      student_data: [],
      search: {
        page: 1,
        month_year: `${new Date().getFullYear()}-${String(
          new Date().getUTCMonth() + 1
        ).padStart(2, "0")}`,
      },
    };
    this.columns = [];
    this.setColumns();

    this.colors = {
      late_punch: "text-yellow-500",
      present: "text-green-500",
      absent: "text-red-500",
      leave: "text-orange-500",
      holiday: "text-primary",
      sunday: "text-gray-300",
    };
  }
  componentDidMount() {
    window.LeaveRequests = this;
    this.fetchData();
  }
  get daylist() {
    let { monthDate = new Date() } = this.state;
    return new Date(monthDate).dateList().map((o) => ({
      name: (
        <>
          <p
            className={Object.className({
              "text-sm text-center fw-bold": true,
            })}
          >
            {o.getDate()}
            <span
              className={Object.className({
                "text-sm text-center ms-2": true,
                "text-red-500": o.getDayName() == "SUN",
                "text-gray-500": o.getDayName() != "SUN",
              })}
            >
              {o.getDayName().toLowerCase().toTitleCase()}
            </span>{" "}
            {o.getMonthDisplay()}
          </p>
        </>
      ),
      key: o.getDate(),
    }));
  }

  get monthDays() {
    let dayListArr = Object.entries(this.daylist);
    return dayListArr.map(([i, daynum]) => ({ key: daynum.key, index: i }));
  }

  renderIntime(obj) {
    if (obj.in_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else {
      return obj.in_time;
    }
  }
  renderOuttime(obj) {
    if (obj.in_time === obj.out_time) {
      return "-";
    } else if (obj.in_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else if (obj.out_time.replace(/[^\d:]/g, "") === "00:00") {
      return "-";
    } else {
      return obj.out_time;
    }
  }
  renderStatus(sts, record) {
    sts = sts.toLowerCase();
    console.log("#####", record);
    if (sts === "present") {
      return <b className={`${this.colors[sts]} text-capitalize`}>{sts}</b>;
    }
    if (sts === "absent") {
      return (
        <b className={`${this.colors["absent"]} text-capitalize`}>{sts}</b>
      );
    }
  }
  timeDiff(start, end) {
    start = start.split(":");
    end = end.split(":");
    if (Number(start[0]) > Number(end[0])) {
      var num = Number(start[0]);
      var countTo = Number(end[0]);
      var count = 0;
      for (var i = 1; num != countTo; ) {
        num = num + i;
        if (num > 24) {
          num = 0;
        }
        count++;
      }
      var hours = count - 1;
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      if (startDate.getMinutes() > endDate.getMinutes()) {
        var hours = count - 2;
        var diff = 60 - (startDate.getMinutes() - endDate.getMinutes());
      } else {
        var diff = endDate.getMinutes() - startDate.getMinutes();
      }
      var minutes = diff;
    } else {
      var startDate = new Date(0, 0, 0, start[0], start[1], 0);
      var endDate = new Date(0, 0, 0, end[0], end[1], 0);
      var diff = endDate.getTime() - startDate.getTime();
      var hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
    }

    if (isNaN(hours)) {
      return "-";
    }
    var returnValue =
      (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
    let mStr = moment(returnValue, "HH:mm:ss").format("hh:mm");
    let formattedTime =
      mStr.split(":")[0] + " hrs" + " " + mStr.split(":")[1] + " mins";
    returnValue = formattedTime;
    return returnValue;
  }
  setColumns() {
    let { daylist, monthDays } = this;
    let year = this.state.monthDate.getFullYear();
    let month = this.state.monthDate.getMonth();
    this.colors = {
      present: "text-green-500",
      absent: "text-red-500",
      leave: "text-orange-500",
      nostatus: "text-orange-200",
      holiday: "text-primary",
      sunday: "text-gray-300",
    };
    this.status = Object.map(this.colors, (value, key) => {
      switch (key) {
        case "leave":
          return <b className={`${this.colors[key]}`}>L</b>;
        case "holiday":
          return <b className={`${this.colors[key]}`}>H</b>;
        case "sunday":
          return <i className={`fa fa-circle ${this.colors[key]} text-xs`}></i>;
        case "nostatus":
          return (
            <i className={`fa fa-circle ${this.colors["sunday"]} text-xs`}></i>
          );
        case "absent":
          return <i className={`fa fa-close ${this.colors[key]}`}></i>;
        default:
          return <i className={`fa fa-check ${this.colors[key]}`}></i>;
      }
    });
    this.columns = [
      {
        dataIndex: "name",
        title: "Name",
      },
      {
        dataIndex: "id",
        display: false,
      },
      {
        dataIndex: "profile_id",
        display: false,
      },
      ...monthDays.map(({ key, index }) => ({
        dataIndex: key,
        title: "",
        sorter: false,
        render: (text, record) => {
          let dateobj = new Date(year, month, key);
          // let holidaydate = app.props.store.holidayList.find(
          //   (o) => new Date(o.holiday_date).toDate() == dateobj.toDate()
          // );
          return (
            <div
              className="text-center"
              title={""}
              onClick={(e) => {
                if (["present", "absent"].includes(text.status)) {
                  confirm(
                    <div>
                      Are you sure want to mark the
                      <br /> status of this student as{" "}
                      <span className="text-orange-500">
                        {text.status === "absent" ? "present" : "absent"}
                      </span>
                      ?
                    </div>,
                    {
                      title: (
                        <div>
                          <em className="la la-exclamation-circle text-orange-500 bounce"></em>
                        </div>
                      ),
                      extra: true,
                      okText: "Yes",
                      cancelText: "No",
                    }
                  ).promise.then(() => {
                    this.props.api
                      .apiUpdateAttendanceHistory(
                        { attendance_id: text?.attendance_id },
                        {
                          attendance_status:
                            text?.attendance_status === "absent"
                              ? "PRESENT"
                              : "ABSENT",
                        }
                      )
                      .then((response) => {
                        toast.success("Attendance updated successfully");
                        this.fetchList();
                      })
                      .catch((error) => {
                        toast.error("Error updating attendance");
                      });
                  });
                }
              }}
            >
              {this.status[text?.status] || this.status["nostatus"]}
            </div>
          );
        },
      })),
    ];
  }

  getDayColumnTitle(month_year,index) {
    let monthDate=new Date(month_year[0], month_year[1] - 1, 1);
    let list= new Date(monthDate).dateList().map((o) => ({
      name: (
        <>
          <p
            className={Object.className({
              "text-sm text-center fw-bold": true,
            })}
          >
            {o.getDate()}
            {" "}
            {o.getMonthDisplay()}
            <span
              className={Object.className({
                "text-sm text-center ms-2": true,
                "text-red-500": o.getDayName() == "SUN",
                "text-gray-500": o.getDayName() != "SUN",
              })}
            >
              ({o.getDayName().toLowerCase().toTitleCase()})
            </span>
          </p>
        </>
      ),
      key: o.getDate(),
    }));

    return list?.[index]?.name;
  }

  onSearch(data) {
    // console.log("onSearch data: ", data);
    if (data.month_year) {
      data.month_year = new Date().formatToYYYYMM(data.month_year);
    }
    this.setState(
      {
        search: {
          ...this.state.search,
          ...Object.except(data, ["class_id", "school_id"]),
        },
      },
      () => {
        this.fetchData();
      }
    );
  }

  fetchData(extra = {}) {
    this.setState({
      loader: true,
    });
    let payload = {
      ...Object.except(this.state.search, ["event_data"]),
    };
    let data={ profile_id: this.state.search?.profile_id};
    data.profile_id===undefined && delete data.profile_id;
    this.props.api
      .apiGetAttendanceBySectionIdForAdmin(
        data,
        payload,
        this.state.search.event_data
      )
      .then(({ data, rawData }) => {
        if (rawData.length > 0) {
          let { daylist, monthDays } = this;
          const attendanceDates = new Set(
            rawData[0]?.attendances?.map((item) =>
              (item.attendance_date || "").date().toDate()
            )
          );

          let month_year = payload.month_year?.split("-");
          const filteredMonthDays = monthDays.filter(({ key }) =>
            attendanceDates.has(
              new Date(month_year[0], month_year[1] - 1, key).toDate()
            )
          );

          let columns1 = [
            {
              dataIndex: "name",
              title: "Name",
            },
            {
              dataIndex: "id",
              display: false,
            },
            {
              dataIndex: "profile_id",
              display: false,
            },
            ...filteredMonthDays.map(({ key, index }) => ({
              dataIndex: key,
              title: this.getDayColumnTitle(month_year,index),
              sorter: false,
              render: (text, record) => {
                let dateobj = new Date(month_year[0], month_year[1] - 1, key);
                return (
                  <DayAttendanceCell
                    text={text}
                    record={record}
                    dateobj={dateobj}
                    status={this.status}
                    fetchList={this.fetchData.bind(this)} // Pass the method to fetch data
                    api={this.props.api} // Pass the API method
                  />
                );
              },
            })),
          ];

          // console.log(columns1);
          this.setState({
            newColumns: columns1,
          });
        }
        this.setState({
          student_data:
            rawData?.map((item) => {
              return { student_name: item?.name, ...item };
            }) || [],
          attendanceData: data,
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  }

  onPrev() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page - 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  onNext() {
    this.setState(
      (state) => ({ search: { ...state.search, page: state.search.page + 1 } }),
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    let { loader, newColumns, student_data } = this.state;
    let { links, columns = [] } = this;
    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <AttendanceSearch
          student_data={this.state.student_data}
          onSearch={(...arg) => this.onSearch(...arg)}
        />
        <div className="row mb-4">
          {this.state.attendanceData.length == 0 ? (
            <div className="empty_attendance_layout bg-white"></div>
          ) : (
            <div className="col">
              <AppTable
                data={this.state.attendanceData}
                // data={dummyResData}
                columns={newColumns}
                editable={false}
                deletable={false}
                reorder={true}
                isRowDeletable={() => false}
                isRowEditable={() => false}
                viewable={false}
                onNext={() => this.onNext()}
                onPrev={() => this.onPrev()}
                total={this.state.rowcount}
                pageSize={10}
              ></AppTable>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect(AttendanceDetailsHistory);
