import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StudentModal from "./StudentModal";
import StudentSearch from "./StudentSearch";
import PasswordResetModal from "./passwordResetModal";

export class StudentManagement extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("StudentManagement");
    this.state = {
      data: [],
      rowcount: 0,
      search: {
        limit: 10,
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
      editData: {},
      columns: [],
      // classArr: ["VI", "VII", "VIII", "IX"],
      currentClass: "VI",
      popupfields: [],
      popupdata: {},
      editMode: false,
      sections: [],
      loader: false,
      classId: "",
      class_name: "",
      openResetPwdModal: false,
      studentCredentials: {
        user_id: "",
        otp: "",
      },
      schoolId: window.store.getState().root.school_id,
      studentFields: [
        {
          type: "text",
          name: "school_name",
          label: "School Name",
          disabled: true,
        },
        {
          type: "select",
          name: "curriculum_id",
          label: "Class Name",
          options: [],
          required: true,
          onChange: (e) => this.handleSections(e),
        },
        {
          type: "select",
          name: "section_id",
          label: "Section",
          options: [],
          required: true,
        },
        {
          type: "text",
          name: "student_name",
          label: "Student Name",
          required: true,
        },
        {
          type: "text",
          name: "email",
          label: "Email ID",
          required: true,
        },
        {
          type: "text",
          name: "user_id",
          label: "User ID",
          disabled: true,
          className: "invoice-user-id",
        },
      ],
    };
    this.classColumns = [
      {
        dataIndex: "user_id",
        title: "User ID",
        disbled: true,
      },
      {
        dataIndex: "curriculum_grade",
        title: "Class Name",
        disbled: true,
      },
      {
        dataIndex: "section",
        title: "Section",
      },
      {
        dataIndex: "name",
        title: "Student Name",
      },
      {
        dataIndex: "email",
        title: "Email ID",
      },
      {
        dataIndex: "student_password",
        title: "Latest Password",
        render: (text) => {
          return text ? `${text}` : "-";
        },
      },
    ];

    this.studentModalRef = React.createRef();
  }

  componentDidMount() {
    this.fetchList();
    // this.fetchStudentData();
    this.fetchClassList();
  }

  handleStoreChange = () => {
    const newSchoolId = window.store.getState().root.school_id;
    if (newSchoolId !== this.state.schoolId) {
      this.setState(
        {
          schoolId: newSchoolId,
        },
        () => this.fetchClassList()
      );
    }
  };

  fetchClassList() {
    this.setState({
      loader: true,
    });
    this.props.api
      .apiGetClassById({ school_id: this.state.schoolId })
      .then((response) => {
        let data = response.data[0].records;
        let classNames = this.state.studentFields.find(
          (o) => o.name == "curriculum_id"
        );
        classNames.options = data?.options("curriculum_grade");
        this.setState({
          loader: false,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
      });
  }

  handleSections(data) {
    let classId = data[0]?.class_id;
    this.setState({
      classId: classId,
    });
    this.setState({
      loader: true,
    });
    this.getClassDetails(classId);
  }

  getClassDetails(classId) {
    let payload = {
      class_id: classId,
    };
    this.props.api
      .apiGetClassDetailsById(payload, {
        class_id: classId,
      })
      .then((response) => {
        // console.log({ apiGetClassDetailsById: response.data });
        let data = response.data.section;
        let classNames = this.state.studentFields.find(
          (o) => o.name == "section_id"
        );
        classNames.options = data?.options("section_id", "name");
        this.setState({
          studentFields: [...this.state.studentFields],
          loader: false,
        });
      })
      .catch((error) => {
        console.log({ error: error });
        this.setState({
          loader: false,
        });
      });
  }

  fetchList() {
    this.props.api
      .apiGetAllSchoolList()
      .then(({ data }) => {
        this.setState({ loader: false });

        let payload = {
          school_id: localStorage.getItem("school_id"),
        };

        this.props.api
          .apiGetAllStudents(this.state.search, payload)
          .then((res) => {
            this.setState({
              data: res.data,
              rowcount: res.total,
              columns: this.classColumns,
              popupfields: this.state.studentFields,
              loader: false,
            });
          })
          .catch(() => {
            this.setState({
              loader: false,
            });
          });

        this.props.api
          .apiGetClassById(payload)
          .then((response) => {
            let data = response.data[0].records;
            let classNames = this.state.studentFields.find(
              (o) => o.name == "curriculum_id"
            );
            classNames.options = data?.options("curriculum_grade");
          })
          .catch(() => {
            this.setState({
              loader: false,
            });
          });
        this.setState({
          loader: false,
        });
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  }

  fetchStudentData(data) {
    this.setState({ loader: true });
    let studentSearch = {
      ...this.state.search,
      ...data,
    };
    const schoolId = localStorage.getItem("school_id");
    let payload = {
      school_id: schoolId,
    };
    this.props.api
      .apiGetAllStudents(studentSearch, payload)
      .then((res) => {
        console.log("students data: ", res);
        console.log("row count", res.total);
        this.setState({
          data: res.data,
          rowcount: res.total,
          columns: this.classColumns,
          popupfields: this.state.studentFields,
          loader: false,
        });
      })
      .catch(() => {
        this.setState({ loader: false });
      });
  }

  onSearch(data) {
    this.setState(
      {
        search: {
          ...this.state.search,
          ...data,
        },
      },
      () => {
        this.fetchStudentData(data);
      }
    );
  }

  onNext() {}

  onPrev() {}

  onPageChange(pageData) {
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 10,
        },
      },
      () => this.fetchStudentData()
    );
  }

  // TABLE FUNCTIONALITIES START
  deleteAction(v) {
    console.log("delete ", v);
    let payload = {
      student_id: v.student_id,
    };

    confirm(`Do you really want to delete this record?`)
      .promise.then(() => {
        this.api.apiDeleteStudent(payload).then(() => {
          success("Record Deleted successfully");
          this.fetchStudentData();
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  }

  editAction(v) {
    console.log("edit: ", v);
    let school_name = localStorage.getItem("school_name");
    this.openStudentModal();
    const updatedEditFields = [...this.state.studentFields];

    const fieldsToDisable = ["user_id"];
    fieldsToDisable.forEach((fieldName) => {
      const fieldIndex = updatedEditFields.findIndex(
        (field) => field.name === fieldName
      );
      if (fieldIndex !== -1) {
        updatedEditFields[fieldIndex] = {
          ...updatedEditFields[fieldIndex],
          disabled: true,
        };
      }
    });

    let fields = (updatedEditFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );

    let popupdata = {
      school_name: school_name,
      curriculum_id: v.curriculum_grade,
      section_id: v.section_id,
      student_name: v.name,
      email: v.email,
      user_id: v.user_id,
    };
    this.getClassDetails(v.class_id);
    this.setState((prevState) => ({
      popupfields: fields,
      popupdata: popupdata,
      editData: v,
      editMode: true,
      viewMode: false,
    }));
  }

  viewAction(v) {
    console.log("view: ", v);

    let school_name = localStorage.getItem("school_name");
    this.openStudentModal();
    let popupdata = {
      school_name: school_name,
      curriculum_id: v.curriculum_grade,
      section_id: v.section_id,
      student_name: v.name,
      email: v.email,
      user_id: v.user_id,
      profile_id: v.profile_id,
    };
    this.getClassDetails(v.class_id);
    this.setState((prevState) => ({
      popupdata: popupdata,
      editData: v,
      editMode: false,
      viewMode: true,
    }));
  }
  // TABLE FUNCTIONALITIES END

  // MODAL FUNCTIONS START
  openStudentModal() {
    this.studentModalRef.current.click();
  }

  closeStudentModal() {
    document.querySelector("#Student_modal #bsmodalclose").click();
  }
  addModalFields(values, modalfields) {
    console.log({ values, modalfields });
    let fields;
    let userField = modalfields.filter((item) => item.name != "user_id");
    fields = (userField || []).reduce((o, n) => {
      o[n.name] = n;
      return o;
    }, {});

    const formdata = Object.keys(fields).reduce((o, k) => {
      o[k] = "";
      return o;
    }, {});
    formdata["school_name"] = localStorage.getItem("school_name");
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }
  // MODAL FUNCTIONS START

  handleResetPassword() {
    this.props.api
      .apiResetStudentPassword({ profile_id: this.state.popupdata.profile_id })
      .then((res) => {
        this.setState({
          openResetPwdModal: true,
          popupdata: {},
          studentCredentials: {
            user_id: res.user_id,
            otp: res.student_password,
          },
        });
        this.fetchStudentData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    let formData = e.target.getData();
    console.log("formData", this.state.editData);
    let school_id = localStorage.getItem("school_id");
    let payload = {
      school_id: school_id,
      class_id: this.state.classId,
      section_id: formData.section_id,
      email: formData.email,
      student_name: formData.student_name,
    };
    if (!this.state.editMode) {
      this.setState({
        loader: true,
      });
      this.props.api
        .apiAddStudent(payload)
        .then((res) => {
          // success("Student added successfully");
          this.closeStudentModal();
          this.setState(
            {
              popupdata: res.data,
            },
            () => {
              this.handleResetPassword();
              this.fetchStudentData();
            }
          );
        })
        .then(() => {
          this.setState({
            loader: false,
          });
        })
        .catch((err) => {
          this.closeStudentModal();
          this.setState({
            loader: false,
          });
        });
    } else {
      let payload = {
        class_id: this.state.classId,
        section_id: formData.section_id,
        email: formData.email,
        student_name: formData.student_name,
      };
      this.setState({
        loader: true,
      });
      this.props.api
        .apiEditStudentById(payload, {
          profile_id: this.state.editData.profile_id,
        })
        .then((res) => {
          success("Student Edited successfully");
          this.closeStudentModal();
          this.fetchStudentData();
        })
        .then(() => {
          this.setState({
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
          });
        });
    }
  }

  downloadFile() {
    if (this.state.class_name) {
      this.props.api
        .apiStudentBulkDownload({
          school_id: window.store.getState().root.school_id,
          class_name: this.state.class_name,
        })
        .then(async (res) => {
          if (res) {
            let data = await res;
            let blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute(
              "download",
              `student_details_${new Date().toDate()}.xlsx`
            );
            link.click();
          }
        })
        .catch((error) => {
          toast.error("There is some error while downloading");
        });
    } else {
      toast.error("Please select class first");
    }
  }

  bulkUpload() {
    let info;
    prompt(
      "",
      {
        name: "file",
        label: "Upload student",
        type: "file",
        required: true,
        onChange: (...e) => {
          info = e?.[0];
        },
      },
      null,
      { okText: "Upload" }
    ).promise.then(() => {
      if (info?.file.status !== "removed") {
        const formData = new FormData();
        formData.append("school_id", this.state.schoolId);
        formData.append("file", info.file.originFileObj);
        this.setState({
          loader: true,
        });
        this.props.api
          .apiStudentBulkUpload(formData)
          .then((res) => {
            const contentType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (res.headers?.["content-type"].includes(contentType)) {
              let data = res.data;
              let blob = new Blob([data], {
                type: contentType,
              });
              let link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.setAttribute(
                "download",
                `error_report_${new Date().toDate()}.xlsx`
              );
              link.click();
              toast.error(
                <div>
                  Error while uploading file!
                  <br />
                  Please refer to the Excel file for more details.
                </div>
              );
            } else {
              toast.success("Students uploaded successfully");
            }
            this.setState({
              loader: false,
            },()=>{
              this.fetchStudentData()
            });
          })
          .catch((error) => {
            if (error.response?.status === 400) {
              const textDecoder = new TextDecoder("utf-8");
              const jsonString = textDecoder.decode(error.response.data);
              const jsonData = JSON.parse(jsonString);
              if (jsonData) {
                toast.error(
                  jsonData?.issues?.error || "Error while uploading file!"
                );
              }
            } else {
              toast.error("Error while uploading file!");
            }
            this.setState({
              loader: false,
            });
          });
      }
    });
  }

  render() {
    let {
      data = [],
      rowcount,
      columns,
      viewMode,
      popupfields,
      editMode,
      popupdata,
      editData,
      loader,
      studentFields,
    } = this.state;

    return (
      <>
        {loader && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col"></div>
              <div className="mb-4">
                <Link
                  className="btn add-btn"
                  ref={this.studentModalRef}
                  data-bs-toggle="modal"
                  data-bs-target="#Student_modal"
                  onClick={(...args) => {
                    this.addModalFields(...args, studentFields);
                  }}
                >
                  <i className="fa fa-plus"></i>
                  Add Student
                </Link>
              </div>
            </div>
            <StudentSearch
              onSearch={(...arg) => this.onSearch(...arg)}
              setClassName={(data) => this.setState({ class_name: data })}
            />
            <div className="row ClassManagement">
              <div className="col-12 my-2">
                <Link
                  className="btn btn_primary col col-md-1 ml-auto grey ms-2 me-2 float-right"
                  onClick={(...args) => this.downloadFile(...args)}
                >
                  <i className="fa fa-download pr-2"></i>
                  Download
                </Link>
                <Link
                  className="btn add-btn mr-2"
                  // ref={this.studentModalRef}
                  // data-bs-toggle="modal"
                  // data-bs-target="#Student_modal"
                  onClick={(...args) => {
                    this.bulkUpload();
                  }}
                >
                  <i className="fa fa-upload"></i>
                 Bulk Upload Students
                </Link>
              </div>
              <div className="col-12">
                {!!data.length && (
                  <AppTable
                    data={data}
                    columns={columns}
                    onNext={() => this.onNext()}
                    onPrev={() => this.onPrev()}
                    onChange={(...arg) => this.onPageChange(...arg)}
                    total={rowcount}
                    reorder={true}
                    pageSize={50}
                    deleteAction={(v) => this.deleteAction(v)}
                    editAction={(v) => this.editAction(v)}
                    viewAction={(v) => this.viewAction(v)}
                    deletable={false}
                    viewable={true}
                    targetType="tap"
                  ></AppTable>
                )}
                {data.length == 0 && <div className="empty_layout"></div>}
              </div>
            </div>
            <StudentModal
              id="Student_modal"
              title={`${
                editMode ? "Update" : viewMode ? "View" : "Add"
              } Student`}
              submitText={`${editMode ? "Update" : "Submit"}`}
              data={popupdata}
              fields={popupfields}
              disablefields={["Curriculum Grade"]}
              disableallfields={viewMode}
              editData={editData}
              submitButton={viewMode ? false : true}
              closeButton={false}
              resetPassword={true}
              onSubmit={(...args) => {
                this.handleSubmit(...args);
              }}
              onClose={() =>
                this.setState({
                  popupdata: {},
                  editData: {},
                  editMode: false,
                  viewMode: false,
                })
              }
              onResetPassword={(...args) => {
                this.handleResetPassword(...args);
              }}
            />
          </div>

          <button
            id="fetch_class"
            className="btn btn-sm btn-outline-secondary d-none"
            onClick={() => this.handleStoreChange()}
          >
            <span>Hidden Button</span>
          </button>
        </div>

        {this.state.openResetPwdModal && (
          <PasswordResetModal
            userId={this.state.studentCredentials.user_id}
            otp={this.state.studentCredentials.otp}
            onClose={() => {
              this.setState({
                openResetPwdModal: false,
              });
            }}
          />
        )}
      </>
    );
  }
}

export default connect(StudentManagement);
