/**
 * App Header
 */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sidemenu from "./sidemenu";

const Sidebar = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const [menu, setMenu] = useState({});
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [schoolNames, setSchoolNames] = useState([]);
  useEffect(() => {
    let currentAdminRole = localStorage.getItem("currentAdminRole");
    setRole(currentAdminRole);
    if (currentAdminRole == "SCHOOL_COORDINATOR") getSchoolNames();
    let email = JSON.parse(localStorage.getItem("profile-data"))?.email;
    setEmail(email);
  }, []);

  const MenuLink = ({
    value,
    iconStyle,
    iconActive,
    name,
    children,
    className,
  }) => (
    <>
      <a
        href="#"
        className={Object.className({
          [className]: true,
          subdrop: isSideMenu == value,
        })}
        onClick={() => toggleSidebar(isSideMenu == value ? "" : value)}
      >
        {
          <div className="menu-icon">
            <img
              src={iconStyle}
              className={Object.className({
                inactive: !pathname.startsWith(menu.path),
                active: pathname.startsWith(menu.path),
                "menu-icon-img": true,
              })}
              width="20px"
              alt=""
            />
            <img
              src={iconActive}
              className={Object.className({
                inactive: pathname.startsWith(menu.path),
                active: !pathname.startsWith(menu.path),
                "menu-icon-img": true,
              })}
              width="20px"
              alt=""
            />
          </div>
        }
        <span>{name}</span>
        <span className="menu-arrow" />
      </a>
      {isSideMenu == value && children}
    </>
  );

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };

  const getSchoolNames = () => {
    setLoader(true);
    props.api
      .apiGetAllSchoolList()
      .then(({ data, total }) => {
        setSchoolNames(data?.options("school_id", "school_name"));
        props.setstore("school_id", data[0].school_id);
      })
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const coordinatorSchoolId = (value) => {
    localStorage.setItem("school_id", value[0]?.school_id);
    props.setstore("school_id", value[0]?.school_id);
    if(window.location.pathname != "/contentmanagement/pillarspage"
    && window.location.pathname != "/contentmanagement/sessionsview/module/videopage"
    && window.location.pathname != "/contentmanagement/sessions") {
      document.getElementById("fetch_class")?.click();
    }
  };

  let pathname = props.location.pathname;
  return (
    <>
      {loader && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="sidebar" id="sidebar">
        {!!menu && " "}
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="metismenu" id="menu">
              {role == "SCHOOL_COORDINATOR" && (
                <li>
                  <li className="menu-title">
                    <span>Menu</span>
                  </li>
                  <li className="menu-title selectFromDrop">
                    <AppInput
                      type="select"
                      name="school_id"
                      label="Select School *"
                      defaultValue={`${localStorage.getItem("school_id")}`}
                      placeholder="Please select"
                      options={schoolNames}
                      className="col-12 school_field"
                      disabled={true}
                      onChange={(e) => coordinatorSchoolId(e)}
                    ></AppInput>
                  </li>
                </li>
              )}
              {/* <li className="menu-title">
                <span>Main</span>
              </li> */}
              {role == "SUPER_ADMIN" &&
                sidemenu?.superAdmin
                  .filter((menu) => {
                    if (email.includes("admin2@cognospace.in")) {
                      return ["/dashboard","/taskmanagement","/schedulemanagement","/attendancemanagement/history"].includes(menu.path) ? menu : null;
                    }
                    return menu;
                  })
                  .map((menu, i) => (
                    <li
                      key={i}
                      className={Object.className({
                        submenu: menu.children && menu.children.length,
                      })}
                    >
                      {menu.children && menu.children.length && (
                        <MenuLink
                          name={menu.label}
                          value={menu.label.toLowerCase()}
                          iconStyle={menu.iconStyle}
                          iconActive={menu.iconActive}
                          className={Object.className({
                            active: pathname.startsWith(menu.path),
                          })}
                        >
                          <ul>
                            {menu.children.map((submenu, j) => (
                              <li key={j}>
                                <Link
                                  className={Object.className({
                                    active: pathname == submenu.path,
                                  })}
                                  onClick={() => setMenu(submenu)}
                                  to={submenu.path}
                                >
                                  {submenu.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </MenuLink>
                      )}
                      {!(menu.children && menu.children.length) && (
                        <Link
                          to={menu.path}
                          onClick={() => setMenu(submenu)}
                          className={Object.className({
                            active:
                              pathname == menu.path ||
                              pathname.startsWith(menu.path),
                          })}
                        >
                          {
                            <div className="menu-icon">
                              <img
                                src={menu.iconStyle}
                                className={Object.className({
                                  inactive: !pathname.startsWith(menu.path),
                                  active: pathname.startsWith(menu.path),
                                  "menu-icon-img": true,
                                })}
                                width="20px"
                                alt=""
                              />
                              <img
                                src={menu.iconActive}
                                className={Object.className({
                                  inactive: pathname.startsWith(menu.path),
                                  active: !pathname.startsWith(menu.path),
                                  "menu-icon-img": true,
                                })}
                                width="20px"
                                alt=""
                              />
                            </div>
                          }
                          <span>{menu.label}</span>
                          {pathname == menu.path && (
                            <span className={`!ml-auto ${menu.path}`}>
                              <em className="la la-hand-o-left"></em>
                            </span>
                          )}
                        </Link>
                      )}
                    </li>
                  ))}
              {role == "SCHOOL_ADMIN" &&
                sidemenu.schoolAdmin.map((menu, i) => (
                  <li
                    key={i}
                    className={Object.className({
                      submenu: menu.children && menu.children.length,
                    })}
                  >
                    {menu.children && menu.children.length && (
                      <MenuLink
                        name={menu.label}
                        value={menu.label.toLowerCase()}
                        iconStyle={menu.iconStyle}
                        iconActive={menu.iconActive}
                        className={Object.className({
                          active: pathname.startsWith(menu.path),
                        })}
                      >
                        <ul>
                          {menu.children.map((submenu, j) => (
                            <li key={j}>
                              <Link
                                className={Object.className({
                                  active: pathname == submenu.path,
                                })}
                                onClick={() => setMenu(submenu)}
                                to={submenu.path}
                              >
                                {submenu.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </MenuLink>
                    )}
                    {!(menu.children && menu.children.length) && (
                      <Link
                        to={menu.path}
                        onClick={() => setMenu(submenu)}
                        className={Object.className({
                          active:
                            pathname == menu.path ||
                            pathname.startsWith(menu.path),
                        })}
                      >
                        {
                          <div className="menu-icon">
                            <img
                              src={menu.iconStyle}
                              className={Object.className({
                                inactive: !pathname.startsWith(menu.path),
                                active: pathname.startsWith(menu.path),
                                "menu-icon-img": true,
                              })}
                              width="20px"
                              alt=""
                            />
                            <img
                              src={menu.iconActive}
                              className={Object.className({
                                inactive: pathname.startsWith(menu.path),
                                active: !pathname.startsWith(menu.path),
                                "menu-icon-img": true,
                              })}
                              width="20px"
                              alt=""
                            />
                          </div>
                        }
                        <span>{menu.label}</span>
                        {pathname == menu.path && (
                          <span className={`!ml-auto ${menu.path}`}>
                            <em className="la la-hand-o-left"></em>
                          </span>
                        )}
                      </Link>
                    )}
                  </li>
                ))}
              {role == "SCHOOL_COORDINATOR" &&
                sidemenu?.schoolCoordinator.map((menu, i) => (
                  <li
                    key={i}
                    className={Object.className({
                      submenu: menu.children && menu.children.length,
                    })}
                  >
                    {menu.children && menu.children.length && (
                      <MenuLink
                        name={menu.label}
                        value={menu.label.toLowerCase()}
                        iconStyle={menu.iconStyle}
                        iconActive={menu.iconActive}
                        className={Object.className({
                          active: pathname.startsWith(menu.path),
                        })}
                      >
                        <ul>
                          {menu.children.map((submenu, j) => (
                            <li key={j}>
                              <Link
                                className={Object.className({
                                  active: pathname == submenu.path,
                                })}
                                onClick={() => setMenu(submenu)}
                                to={submenu.path}
                              >
                                {submenu.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </MenuLink>
                    )}
                    {!(menu.children && menu.children.length) && (
                      <Link
                        to={menu.path}
                        onClick={() => setMenu(submenu)}
                        className={Object.className({
                          active:
                            pathname == menu.path ||
                            pathname.startsWith(menu.path),
                        })}
                      >
                        {
                          <div className="menu-icon">
                            <img
                              src={menu.iconStyle}
                              className={Object.className({
                                inactive: !pathname.startsWith(menu.path),
                                active: pathname.startsWith(menu.path),
                                "menu-icon-img": true,
                              })}
                              width="20px"
                              alt=""
                            />
                            <img
                              src={menu.iconActive}
                              className={Object.className({
                                inactive: pathname.startsWith(menu.path),
                                active: !pathname.startsWith(menu.path),
                                "menu-icon-img": true,
                              })}
                              width="20px"
                              alt=""
                            />
                          </div>
                        }
                        <span>{menu.label}</span>
                        {pathname == menu.path && (
                          <span className={`!ml-auto ${menu.path}`}>
                            <em className="la la-hand-o-left"></em>
                          </span>
                        )}
                      </Link>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(Sidebar);
